/**
 * @file standalone/colorbox.scss
 *
 * \brief Library file - shared styles for colorbox
 *
 */

@import '../theme-bootstrap';
@import '../00-global/icons/icons-mixins';

#colorbox,
#cboxOverlay,
#cboxWrapper {
  background-color: green;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
  outline: none;
}

#cboxWrapper {
  max-width: none;
}

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
}

#cboxMiddleLeft,
#cboxBottomLeft {
  clear: left;
}

#cboxContent {
  position: relative;
}

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

#cboxTitle {
  margin: 0;
}

#cboxLoadingOverlay,
#cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
  cursor: pointer;
}

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
}

#colorbox,
#cboxContent,
#cboxLoadedContent {
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

///
///  Colorbox User Style Overrides:
///

#cboxOverlay {
  @include opacity(0.9);
  background: $color-white;
  border: 1px solid $color-gray;
  .device-mobile & {
    @include opacity(1);
    // background: $color-white;
  }
}

#colorbox {
  @include breakpoint($medium-up) {
    border: 1px solid $color-gray;
  }
}

#cboxTopLeft {
  display: none;
  width: 0;
  height: 0;
  background: none;
}

#cboxTopRight {
  display: none;
  width: 0;
  height: 0;
  background: none;
}

#cboxBottomLeft {
  display: none;
  width: 0;
  height: 0;
  background: none;
}

#cboxBottomRight {
  display: none;
  width: 0;
  height: 0;
  background: none;
}

#cboxMiddleLeft {
  display: none;
  width: 0;
  background: none;
}

#cboxMiddleRight {
  display: none;
  width: 0;
  background: none;
}

#cboxTopCenter {
  display: none;
  height: 0;
  background: none;
}

#cboxBottomCenter {
  display: none;
  height: 0;
  background: none;
}

#cboxContent {
  background: $color-white;
  padding: 25px;
  overflow: hidden;
  h3 {
    @include h24;
    text-indent: 0;
    margin: 4px 0 10px 0;
    padding-bottom: 16px;
    border-bottom: 1px solid $color-black;
  }
}
// .cboxIframe{background:#fff;}
// #cboxError{padding:50px; border:1px solid #ccc;}
#cboxLoadedContent {
  margin-bottom: 0;
  color: $color-black;
}

#cboxClose {
  @include squish-text;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 100;
  color: $color-gray-dark;
  width: 18px;
  height: 18px;
  font-size: 0;
  cursor: pointer;
  overflow: hidden;
  border: none;
  -webkit-appearance: none;

  //reset button
  background: $color-white;
  padding: 0;
  display: block;
  &::before {
    content: '';
    width: 18px;
    height: 18px;
    background: url('#{$base-theme-path}img/icons/src/close.svg') no-repeat;
    background-size: cover;
    display: block;
  }
}

#cboxClose:hover {
  //@include estee-sprites('cbox_close');
}

#cboxLoadingGraphic {
  @include ajaxSpinner($color-gray-dark, 30px, 30px, 8px);
  background: none;
  position: absolute;
  left: 50%;
  top: 50%;
  @include transform(translateX(-50%) translateY(-50%));
}

#cboxLoadingOverlay {
  background: none;
}

// hide body scroll bars when colorbox is on
.colorbox-on {
  overflow: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
}
